import React from "react";
import { Helmet } from "react-helmet";
import { Script } from "gatsby";

const requestIdleCallbackFallback = (fn, timeout = 5000) => {
  if ("requestIdleCallback" in window) {
    requestIdleCallback(fn, { timeout });
  } else {
    setTimeout(fn, timeout);
  }
};

export default function GlobalHtmlHead() {
  return (
    <>
      {/* Note: Gatsby <Script> components are appended to the end of the document <body> instead of <head> */}

      <Script id="wisepops-init" defer strategy="idle" data-cfasync="false">
        {`
          (function (w, i, s, e) {
            window[w] =
              window[w] ||
              function () {
                (window[w].q = window[w].q || []).push(arguments);
              };
            window[w].l = Date.now();
            s = document.createElement("script");
            e = document.getElementsByTagName("script")[0];
            s.defer = 1;
            s.src = i;
            e.parentNode.insertBefore(s, e);
          })("wisepops", "https://wisepops.net/loader.js?v=2&h=xKZm25umVo");
        `}
      </Script>

      {/* <!--MNTN Tracking Pixel--> */}
      <Script id="mntn-tracking-pixel" defer strategy="idle">
        {`
          (function(){"use strict";var e=null,b="4.0.0",
          n="35155",
          additional="term=value",
          t,r,i;try{t=top.document.referer!==""?encodeURIComponent(top.document.referrer.substring(0,2048)):""}catch(o){t=document.referrer!==null?document.referrer.toString().substring(0,2048):""}try{r=window&&window.top&&document.location&&window.top.location===document.location?document.location:window&&window.top&&window.top.location&&""!==window.top.location?window.top.location:document.location}catch(u){r=document.location}try{i=parent.location.href!==""?encodeURIComponent(parent.location.href.toString().substring(0,2048)):""}catch(a){try{i=r!==null?encodeURIComponent(r.toString().substring(0,2048)):""}catch(f){i=""}}var l,c=document.createElement("script"),h=null,p=document.getElementsByTagName("script"),d=Number(p.length)-1,v=document.getElementsByTagName("script")[d];if(typeof l==="undefined"){l=Math.floor(Math.random()*1e17)}h="dx.mountain.com/spx?"+"dxver="+b+"&shaid="+n+"&tdr="+t+"&plh="+i+"&cb="+l+additional;c.type="text/javascript";c.src=("https:"===document.location.protocol?"https://":"http://")+h;v.parentNode.insertBefore(c,v)})()
        `}
      </Script>

      <Script id="bing-ads-tracking-script" defer strategy="idle">
        {`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"97065788"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}
      </Script>

      <Script id="drift-chat" defer strategy="idle">
        {`
            "use strict";

            // Wait until main thread is idle to load Drift.
            // If main thread is not idle after 5 seconds, load Drift anyways.
            (${requestIdleCallbackFallback.toString()})(() => {

                !function() {
                  var t = window.driftt = window.drift = window.driftt || [];
                  if (!t.init) {
                  if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
                  t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
                  t.factory = function(e) {
                  return function() {
                  var n = Array.prototype.slice.call(arguments);
                  return n.unshift(e), t.push(n), t;
                };
                }, t.methods.forEach(function(e) {
                  t[e] = t.factory(e);
                }), t.load = function(t) {
                  var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                  o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                  var i = document.getElementsByTagName("script")[0];
                  i.parentNode.insertBefore(o, i);
                };
                }
                }();
                drift.SNIPPET_VERSION = '0.3.1';

                // this is making FullStory work with Drift
                window._fs_namespace = 'FS';

                // Delay drift until interactive to avoid drift hogging the main thread
                window.drift_init_options = { loadType: 'ON_INTERACTIVE' }

                drift.load('86gdyuuximra');

                // Drift callbacks
                drift.on("ready", function(api) {

                  drift.on('startConversation', function (event) {
                    window.analytics && window.analytics.track("Drift: Conversation Started");
                  });

                  drift.on("conversation:firstInteraction", function(data) {
                    window.analytics && window.analytics.track("Drift: First Interaction");
                  });

                  window.drift.on("emailCapture", function(e) {
                    window.analytics && window.analytics.track("Drift: Email Captured");

                    if (window.analytics) {
                      window.analytics.identify({
                        email: e.data["email"]
                      });
                    }
                  });

                  drift.on("chatOpen", function(data) {
                    window.FS && window.FS.event("Drift: Chat Opened");
                  });

                  drift.on("chatClose", function(data) {
                    window.FS && window.FS.event("Drift: Chat Closed");
                  });

                  drift.on("campaign:open", function(data) {
                    window.FS && window.FS.event("Drift: Campaign Open");
                  });

                  drift.on("campaign:dismiss", function(data) {
                    window.FS && window.FS.event("Drift: Campaign Dismiss");
                  });

                  drift.on("campaign:click", function(data) {
                    window.FS && window.FS.event("Drift: Campaign Click");
                  });

                  drift.on("campaign:submit", function(data) {
                    window.FS && window.FS.event("Drift: Campaign Submit");
                  });

                  drift.on("message:sent", function(data) {
                    window.FS && window.FS.event("Drift: Message Sent");
                  });

                  drift.on("message", function(data) {
                    window.FS && window.FS.event("Drift: Message Received");
                  });

                  drift.on("conversation:playbookFired", function(data) {
                    window.FS && window.FS.event("Drift: Playbook Fired");
                  });

                  drift.on("conversation:playbookClicked", function(data) {
                    window.FS && window.FS.event("Drift: Playbook Clicked");
                  });

                  drift.on("conversation:playbookDismissed", function(data) {
                    window.FS && window.FS.event("Drift: Playbook Dismissed");
                  });

                  drift.on("welcomeMessage:open", function(data) {
                    window.FS && window.FS.event("Drift: Welcome Message Open");
                  });

                  drift.on("welcomeMessage:close", function(data) {
                    window.FS && window.FS.event("Drift: Welcome Message Close");
                  });

                });

            }, { timeout: 5000 });

          `}
      </Script>

      <Script id="tiktok" defer strategy="idle">
        {`
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
        )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

              ttq.load('CHJ84K3C77U8RIVSQ0A0');
              ttq.page();
            }(window, document, 'ttq');
          `}
      </Script>

      <Script id="pinterest" defer strategy="idle">
        {`
            !function(e){if(!window.pintrk){window.pintrk = function () {
            window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
              n=window.pintrk;n.queue=[],n.version="3.0";var
              t=document.createElement("script");t.async=!0,t.src=e;var
              r=document.getElementsByTagName("script")[0];
              r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
            pintrk('load', '2612778737184', {em: '<user_email_address>'});
            pintrk('page');
          `}
      </Script>

      <Helmet>
        {/* We're preloading our fonts here so they don't block first render */}
        <link
          rel="preload"
          href="/fonts/tt-commons-var-roman-subset.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/tt-commons-var-italic-subset.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/tt-commons-mono-md.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <noscript>
          {`
            <img
              height="1"
              width="1"
              style="display:none;"
              alt=""
              src="https://ct.pinterest.com/v3/?event=init&tid=2612778737184&pd[em]=<hashed_email_address>&noscript=1"
            />
          `}
        </noscript>
      </Helmet>
    </>
  );
}
